import React from 'react'
import './listings.scss';

import 'react-input-range/lib/css/index.css';
import Search from './../Search/Search';
import Listing from './../Listings/Listing';
import * as _ from 'underscore';

class SimilarListings extends React.Component {

  constructor(props){
    super(props);
  }
  componentWillMount(){
    this.setState({
      filteredVehicleData: this.props.vehicleData,
      vehicleData: this.props.vehicleData
    })
  }
  componentDidMount(){
    this.searchListings();
  }
  searchListings = async ()=>{
    // console.log('search button clciked');
    let searchQuery = await JSON.parse(sessionStorage.getItem('NZDNiccarsSearchQuery'));

    let searchObject = {};

    if(this.props.similarMake && this.props.similarMake!==""){
      searchObject.make = this.props.similarMake
    }
    //forcing the mazda vehicles
    searchObject.make = 'Mazda';



    //first level of search
    let filterdList = _.where(this.props.vehicleData, searchObject);


    filterdList = _.shuffle(filterdList);

    filterdList = filterdList.slice(0, 4);

    this.setState({
      filteredVehicleData: filterdList
    })
  }

  filterList(searchQuery){
    // console.log('filter list');
  }

  render(){
    return(
      <div className="similar-listing-page">

        <div className="listings-wrapper-wrapper">
          <div className="listings-heading-wrapper">
            <div>
              <h3>SIMILAR VEHICLES</h3>
              <p>This one not quite the one? Maybe one of these is...</p>
            </div>
          </div>
          <div className="listings-wrapper">
            {
              this.state.filteredVehicleData.map((vehicle, key)=>{
                return <Listing key={key} vehicle={vehicle} dealOfTheWeek={this.props.dealOfTheWeek}/>
              })
            }
          </div>
        </div>
        <Search vehicleData={this.props.vehicleData} numberOfResults={this.state.filteredVehicleData.length}/>

      </div>
    )
  }
}

export default SimilarListings;
