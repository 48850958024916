import React from "react";
import "./search.scss";
import * as IO from "react-icons/lib/io";
import { Dropdown, Input, Button, Accordion, Icon } from "semantic-ui-react";
import "react-input-range/lib/css/index.css";
import * as _ from "underscore";
import MediaQuery from "react-responsive";
import InputRange from "react-input-range";

const iconStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  margin: "12px 1px",
  right: "10px",
  lineHeight: 1,
  zIndex: 1,
};

const mobileIconStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  margin: "10px -2px",
  right: "10px",
  lineHeight: 1,
  zIndex: 1,
};

const containerStyle = {
  position: "relative",
  display: "inline-block",
};

class Search extends React.Component {
  state = {
    searchVehicles: "",
    locationOpen: false,
    locationSelected: "",
    locations: [],
    makesOpen: false,
    makesSelected: "",
    makes: [],
    modelsOpen: false,
    modelsSelected: "",
    models: [],
    conditionOpen: false,
    conditionSelected: "",
    condition: [],
    transmissionOpen: false,
    transmissonTypesSelected: "",
    transmissonTypes: [],
    fuelTypeOpen: false,
    fuelTypeSelected: "",
    fuelType: [],
    driveModeOpen: false,
    driveModeSelected: "",
    driveMode: [],
    bodyStyleSelected: "",
    bodyStyle: [],
    price: { min: 2, max: 10 },
    year: { min: 2, max: 10 },
    mileage: { min: 2, max: 10 },
    finance: { min: 10, max: 600 },
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      active: this.props.openAccordianOnFirstLoad ? true : false,
      driveMode: this.getOptions(
        _.uniq(this.props.vehicleData, "drivetrain").map((v) => v.drivetrain)
      ),
      bodyStyle: this.getOptions(
        _.sortBy(
          _.uniq(this.props.vehicleData, "bodyStyle").map((v) => v.bodyStyle),
          (s) => s.charCodeAt()
        )
      ),
      fuelType: this.getOptions(
        _.uniq(this.props.vehicleData, "fuel").map((v) => v.fuel)
      ),
      transmissonTypes: this.getOptions(
        _.uniq(this.props.vehicleData, "transmission").map(
          (v) => v.transmission
        )
      ),
      condition: this.getOptions(
        _.uniq(this.props.vehicleData, "condition").filter((v) => v.condition!=="Demonstrators").map((v)=>v.condition)
      ),
      models: this.getOptions(
        _.sortBy(
          _.uniq(this.props.vehicleData, "model").map((v) => v.model),
          (s) => s.charCodeAt()
        )
      ),
      makes: this.getOptions(
        _.sortBy(
          _.uniq(this.props.vehicleData, "make").map((v) => v.make),
          (s) => s.charCodeAt()
        )
      ),
      locations: this.getOptions(
        _.uniq(this.props.vehicleData, "location").map((v) => v.location)
      ).filter(function (l) {
        if (l.text == "Mazda Whakatane" || l.text == "The Hub") {
          return false;
        }

        return true;
      }),
      year: {
        min: parseInt(
          _.min(this.props.vehicleData, (v) => parseInt(v.year)).year
        ),
        max: parseInt(
          _.max(this.props.vehicleData, (v) => parseInt(v.year)).year
        ),
      },
      mileage: {
        min: parseInt(
          _.min(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
        ),
        max: parseInt(
          _.max(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
        ),
      },
      price: {
        min: parseInt(2995),
        max: parseInt(
          _.max(this.props.vehicleData, (v) => parseInt(v.price)).price
        ),
      },
    };
  }

  getOptions = (arr) => {
    if (arr[0]) {
      return arr.map((item) => {
        return { text: item, value: item };
      });
    } else {
      return [];
    }
  };

  handleActive = (e) => {
    this.setState({ active: !this.state.active });
  };
  async componentWillMount() {
    let searchQuery = await JSON.parse(
      sessionStorage.getItem("NZDNiccarsSearchQuery")
    );

    if (searchQuery) {
      let searchObject = {
        make: this.props.makesSelected || searchQuery.makesSelected,
      };
      let makeArray = [];
      if (this.props.makesSelected || searchQuery.makesSelected) {
        makeArray = _.where(this.props.vehicleData, searchObject);
      }

      let array = makeArray[0] ? makeArray : this.props.vehicleData;

      this.setState({
        searchVehicles: searchQuery.searchVehicles,
        locationSelected: searchQuery.locationSelected,
        makesSelected: this.props.makesSelected || searchQuery.makesSelected,
        modelsSelected: searchQuery.modelsSelected,
        conditionSelected:
          this.props.conditionSelected || searchQuery.conditionSelected,
        transmissonTypesSelected: searchQuery.transmissonTypesSelected,
        fuelTypeSelected: searchQuery.fuelType,
        driveModeSelected: searchQuery.driveMode,
        bodyStyleSelected: searchQuery.bodyStyle,
        // ...stateObject,
        price: searchQuery.price,
        year: searchQuery.year,
        mileage: searchQuery.mileage,
        models: this.getOptions(
          _.sortBy(
            _.uniq(array, "model").map((v) => v.model),
            (s) => s.charCodeAt()
          )
        ),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.conditionSelected !== this.state.conditionSelected &&
      nextProps.conditionSelected != ""
    ) {
      this.setState({ conditionSelected: nextProps.conditionSelected });
    }
    if (
      nextProps.makesSelected !== this.state.makesSelected &&
      nextProps.makesSelected != ""
    ) {
      this.setState({ makesSelected: nextProps.makesSelected });
    }

    if (
      nextProps.modelsSelected !== this.state.modelsSelected &&
      nextProps.modelsSelected != ""
    ) {
      this.setState({ modelsSelected: nextProps.modelsSelected });
    }

    if (nextProps.year !== this.state.year) {
      if (nextProps.year && nextProps.year.min && nextProps.year.max) {
        this.setState({ year: nextProps.year });
      }
    }
  }

  search = (e) => {
    if (e) {
      e.preventDefault();
    }
    let searchQuery = {
      searchVehicles: this.state.searchVehicles,
      locationSelected: this.state.locationSelected,
      makesSelected: this.state.makesSelected,
      modelsSelected: this.state.modelsSelected,
      conditionSelected: this.state.conditionSelected,
      transmissonTypesSelected: this.state.transmissonTypesSelected,
      fuelType: this.state.fuelTypeSelected,
      driveMode: this.state.driveModeSelected,
      bodyStyle: this.state.bodyStyleSelected,
      price: this.state.price,
      year: this.state.year,
      mileage: this.state.mileage,
      finance: this.state.finance,
    };

    sessionStorage.setItem(
      "NZDNiccarsSearchQuery",
      JSON.stringify(searchQuery)
    );

    var event = new Event("changeSearchForm");
    document.getElementsByTagName("body")[0].dispatchEvent(event);

    if (this.props.searchListings) {
      this.props.searchListings();
    } else {
      window.location.href = `/inventory-listings/`;
    }
  };

  onKeyPress = (e) => {
    if ((e.which == 13 || e.keyCode == 13) && this.props.onKeyPress) {
      //enter key
      // this.props.onKeyPress(this.state.locationValue, this.state.categoryValue);
      // return false;
    }
  };

  onKeyPress = (e) => {
    if ((e.which == 13 || e.keyCode == 13) && this.props.onKeyPress) {
      //enter key
      // this.props.onKeyPress(this.state.locationValue, this.state.categoryValue);
      // return false;
    }
  };

  getInitialState = () => {
    return { open: false };
  };

  handleOpen = (e) => {
    this.setState({ open: true });
  };

  handleCloseState = (e, b) => {
    switch (b.placeholder) {
      case "Sort By Location": //location
        this.setState({
          locationOpen: false,
          locationSelected: b.value ? b.value : this.state.locationSelected,
        });

        var event = new Event("changeSearchForm");
        document.getElementsByTagName("body")[0].dispatchEvent(event);

        break;
      case "Make": //make
        let searchObject = {
          make: b.value,
        };
        let array = _.where(this.props.vehicleData, searchObject);

        this.setState({
          makesOpen: false,
          makesSelected: b.value ? b.value : this.state.makesSelected,
          models: this.getOptions(
            _.sortBy(
              _.uniq(array, "model").map((v) => v.model),
              (s) => s.charCodeAt()
            )
          ),
        });
        break;
      case "Model": //make
        this.setState({
          modelsOpen: false,
          modelsSelected: b.value ? b.value : this.state.modelsSelected,
        });
        break;
      case "Condition": //make
        this.setState({
          conditionOpen: false,
          conditionSelected: b.value ? b.value : this.state.conditionSelected,
        });
        break;
      case "Transmisson": //make
        this.setState({
          transmissionOpen: false,
          transmissonTypesSelected: b.value
            ? b.value
            : this.state.transmissonTypesSelected,
        });
        break;
      case "Fuel Type": //make
        this.setState({
          fuelTypeOpen: false,
          fuelTypeSelected: b.value ? b.value : this.state.fuelTypeSelected,
        });
        break;
      case "2WD/4WD": //make
        this.setState({
          driveModeOpen: false,
          driveModeSelected: b.value ? b.value : this.state.driveModeSelected,
        });
        break;
      case "Body Style": //bodyStyle
        this.setState({
          bodyStyleOpen: false,
          bodyStyleSelected: b.value ? b.value : this.state.bodyStyleSelected,
        });
        break;
      default:
    }
  };

  handleClear = (e, b) => {
    switch (b.placeholder) {
      case "Sort By Location": //location
        this.setState({ locationOpen: false, locationSelected: "" });
        break;
      case "Make": //make
        let searchObject = {
          make: b.value,
        };
        let array = _.where(this.props.vehicleData, searchObject);

        this.setState({
          makesOpen: false,
          makesSelected: "",
          models: this.getOptions(
            _.sortBy(
              _.uniq(array, "model").map((v) => v.model),
              (s) => s.charCodeAt()
            )
          ),
        });
        break;
      case "Model": //make
        this.setState({ modelsOpen: false, modelsSelected: "" });
        break;
      case "Condition": //make
        this.setState({ conditionOpen: false, conditionSelected: "" });
        break;
      case "Transmisson": //make
        this.setState({
          transmissionOpen: false,
          transmissonTypesSelected: "",
        });
        break;
      case "Fuel Type": //make
        this.setState({ fuelTypeOpen: false, fuelTypeSelected: "" });
        break;
      case "2WD/4WD": //make
        this.setState({ driveModeOpen: false, driveModeSelected: "" });
        break;
      case "Body Style": //bodyStyle
        console.log("TESTBODY");
        this.setState({ bodyStyleOpen: false, bodyStyleSelected: "" });
        break;
      default:
    }
  };

  handleOpenState = (e, b) => {
    switch (b.placeholder) {
      case "Sort By Location": //location
        this.setState({
          locationOpen: true,
          locationSelected: b.value ? b.value : this.state.locationSelected,
        });
        break;
      case "Make": //make
        this.setState({
          makesOpen: true,
          makesSelected: b.value ? b.value : this.state.makesSelected,
        });
        break;
      case "Model": //make
        this.setState({
          modelsOpen: true,
          modelsSelected: b.value ? b.value : this.state.modelsSelected,
        });
        break;
      case "Condition": //make
        this.setState({
          conditionOpen: true,
          conditionSelected: b.value ? b.value : this.state.conditionSelected,
        });
        break;
      case "Transmisson": //make
        this.setState({
          transmissionOpen: true,
          transmissonTypesSelected: b.value
            ? b.value
            : this.state.transmissonTypesSelected,
        });
        break;
      case "Fuel Type": //make
        this.setState({
          fuelTypeOpen: true,
          fuelTypeSelected: b.value ? b.value : this.state.fuelTypeSelected,
        });
        break;
      case "2WD/4WD": //make
        this.setState({
          driveModeOpen: true,
          driveModeSelected: b.value ? b.value : this.state.driveModeSelected,
        });
        break;
      case "Body Style": //make
        this.setState({
          bodyStyleOpen: true,
          bodyStyleSelected: b.value ? b.value : this.state.bodyStyleSelected,
        });
        break;
      default:
    }
  };
  onSliderChange = (value) => {
    log(value);
    this.setState({
      value,
    });
  };

  onClear = async (e) => {
    e.preventDefault();
    await sessionStorage.removeItem("NZDNiccarsSearchQuery");

    await new Promise((resolve) =>
      this.setState(
        {
          searchVehicles: "",
          locationSelected: "",
          makesSelected: "",
          modelsSelected: "",
          conditionSelected: "",
          transmissonTypesSelected: "",
          fuelTypeSelected: "",
          driveModeSelected: "",
          bodyStyleSelected: "",
          year: {
            min: parseInt(
              _.min(this.props.vehicleData, (v) => parseInt(v.year)).year
            ),
            max: parseInt(
              _.max(this.props.vehicleData, (v) => parseInt(v.year)).year
            ),
          },
          mileage: {
            min: parseInt(
              _.min(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
            ),
            max: parseInt(
              _.max(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
            ),
          },
          finance: { min: 30, max: 600 },
          price: {
            min: parseInt(2995),
            max: parseInt(
              _.max(this.props.vehicleData, (v) => parseInt(v.price)).price
            ),
          },
        },
        resolve
      )
    );

    this.search();
  };

  render() {
    return (
      <div className="search-box-wrapper">
        <MediaQuery minDeviceWidth={600}>
          {(matches) => {
            if (!matches) {
              return (
                <Accordion fluid styled>
                  <Accordion.Title
                    active={this.state.active}
                    index={0}
                    onClick={this.handleActive}
                  >
                    <h1>search vehicles</h1>
                    <Icon name="dropdown" />
                  </Accordion.Title>
                  <Accordion.Content active={this.state.active}>
                    <div className="search-box">
                      <div className="title-section">
                        <div className="clearButton">
                          <div className="search-results">
                            {this.props.numberOfResults
                              ? `${this.props.numberOfResults} Vehicles Found `
                              : null}
                          </div>
                          <a href="#" onClick={this.onClear}>
                            <IO.IoIosRefreshEmpty size={24} /> CLEAR
                          </a>
                        </div>
                      </div>
                      <div className="search-row-one">
                        <Input
                          icon="search"
                          placeholder="Search Vehicles"
                          value={this.state.searchVehicles}
                          onChange={(e, data) => {
                            this.setState({ searchVehicles: data.value });
                          }}
                        />

                        <div style={containerStyle}>
                          {this.state.locationSelected ? (
                            <Icon
                              link
                              name="close"
                              placeholder="Sort By Location"
                              style={mobileIconStyle}
                              onClick={this.handleClear}
                            />
                          ) : null}
                          <Dropdown
                            key="location"
                            ref="location"
                            clearable
                            open={this.state.locationOpen}
                            onBlur={this.handleCloseState}
                            onFocus={this.handleOpenState}
                            onClick={this.handleOpenState}
                            onChange={this.handleCloseState}
                            placeholder="Sort By Location"
                            fluid
                            selection
                            options={this.state.locations}
                            value={this.state.locationSelected}
                          />
                        </div>
                      </div>
                      <div className="search-row-whaka-check"></div>
                      <div className="search-row-two">
                        <div className="column col-1">
                          <div style={containerStyle}>
                            {this.state.makesSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Make"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}
                            <Dropdown
                              key="make"
                              clearable
                              open={this.state.makesOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Make"
                              fluid
                              selection
                              options={this.state.makes}
                              value={this.state.makesSelected}
                            />
                          </div>
                          <div style={containerStyle}>
                            {this.state.modelsSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Model"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}
                            <Dropdown
                              key="models"
                              clearable
                              open={this.state.modelsOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Model"
                              fluid
                              selection
                              options={this.state.models}
                              value={this.state.modelsSelected}
                            />
                          </div>
                          <div style={containerStyle}>
                            {this.state.conditionSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Condition"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}

                            <Dropdown
                              key="conditions"
                              clearable
                              open={this.state.conditionOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Condition"
                              fluid
                              selection
                              options={this.state.condition}
                              value={this.state.conditionSelected}
                            />
                          </div>
                        </div>
                        <div className="column col-2">
                          <div style={containerStyle}>
                            {this.state.transmissonTypesSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Transmisson"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}

                            <Dropdown
                              key="transmission"
                              clearable
                              open={this.state.transmissionOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Transmisson"
                              fluid
                              selection
                              options={this.state.transmissonTypes}
                              value={this.state.transmissonTypesSelected}
                            />
                          </div>

                          <div style={containerStyle}>
                            {this.state.fuelTypeSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Fuel Type"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}
                            <Dropdown
                              key="fuelType"
                              clearable
                              open={this.state.fuelTypeOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Fuel Type"
                              fluid
                              selection
                              options={this.state.fuelType}
                              value={this.state.fuelTypeSelected}
                            />
                          </div>
                          <div style={containerStyle}>
                            {this.state.bodyStyleSelected ? (
                              <Icon
                                link
                                name="close"
                                placeholder="Body Style"
                                style={mobileIconStyle}
                                onClick={this.handleClear}
                              />
                            ) : null}
                            <Dropdown
                              key="bodyStyle"
                              open={this.state.bodyStyleOpen}
                              onBlur={this.handleCloseState}
                              onFocus={this.handleOpenState}
                              onClick={this.handleOpenState}
                              onChange={this.handleCloseState}
                              placeholder="Body Style"
                              fluid
                              selection
                              options={this.state.bodyStyle}
                              value={this.state.bodyStyleSelected}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="search-row-three">
                        <div className="range-input-wrapper">
                          <p>
                            Price{" "}
                            {`$${this.state.price.min} - $${this.state.price.max}`}
                          </p>
                          <InputRange
                            maxValue={parseInt(
                              _.max(this.props.vehicleData, (v) =>
                                parseInt(v.price)
                              ).price
                            )}
                            minValue={parseInt(2995)}
                            formatLabel={(value) => ``}
                            step={1000}
                            value={this.state.price}
                            onChange={(price) => {
                              return this.setState({ price });
                            }}
                          />
                        </div>
                        <div className="range-input-wrapper">
                          <p>
                            Year{" "}
                            {`${this.state.year.min} - ${this.state.year.max}`}
                          </p>
                          <InputRange
                            maxValue={parseInt(
                              _.max(this.props.vehicleData, (v) =>
                                parseInt(v.year)
                              ).year
                            )}
                            minValue={parseInt(
                              _.min(this.props.vehicleData, (v) =>
                                parseInt(v.year)
                              ).year
                            )}
                            formatLabel={(value) => ``}
                            value={this.state.year}
                            onChange={(year) => this.setState({ year })}
                          />
                        </div>

                        {false ? (
                          <div className="range-input-wrapper">
                            <p>
                              Mileage{" "}
                              {`${this.state.mileage.min}km - ${this.state.mileage.max}km`}
                            </p>
                            <InputRange
                              draggableTrack={true}
                              onChangeStart={() => {}}
                              maxValue={parseInt(
                                _.max(this.props.vehicleData, (v) =>
                                  parseInt(v.mileage)
                                ).mileage
                              )}
                              minValue={0}
                              step={5000}
                              formatLabel={(value) => ``}
                              value={this.state.mileage}
                              onChange={(mileage) => this.setState({ mileage })}
                            />
                          </div>
                        ) : (
                          <div className="range-input-wrapper">
                            <p>
                              Finance{" "}
                              {`$${this.state.finance.min} - $${this.state.finance.max}`}{" "}
                              p/wk
                            </p>
                            <InputRange
                              draggableTrack={true}
                              onChangeStart={() => {}}
                              maxValue={600}
                              minValue={30}
                              step={10}
                              formatLabel={(value) => ``}
                              value={this.state.finance}
                              onChange={(finance) => {
                                if (finance.min < 30) {
                                  return this.setState({
                                    finance: { min: 0, max: finance.max },
                                  });
                                }
                                return this.setState({ finance });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="search-row-four">
                        <Button negative onClick={this.search}>
                          Search Vehicles
                        </Button>
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion>
              );
            } else {
              return (
                <div className="search-box">
                  <div className="title-section">
                    <h1>search vehicles</h1>
                    <div className="clearButton">
                      <div className="search-results">
                        {this.props.numberOfResults
                          ? `${this.props.numberOfResults} Vehicles Found `
                          : null}
                      </div>
                      <a href="#" onClick={this.onClear}>
                        <IO.IoIosRefreshEmpty size={24} /> CLEAR
                      </a>
                    </div>
                  </div>
                  <div className="search-row-one">
                    <Input
                      icon="search"
                      placeholder="Search Vehicles"
                      value={this.state.searchVehicles}
                      onChange={(e, data) => {
                        this.setState({ searchVehicles: data.value });
                      }}
                    />
                    <div style={containerStyle}>
                      {this.state.locationSelected ? (
                        <Icon
                          link
                          name="close"
                          placeholder="Sort By Location"
                          style={iconStyle}
                          onClick={this.handleClear}
                        />
                      ) : null}
                      <Dropdown
                        key="location"
                        ref="location"
                        clearable
                        open={this.state.locationOpen}
                        onBlur={this.handleCloseState}
                        onFocus={this.handleOpenState}
                        onClick={this.handleOpenState}
                        onChange={this.handleCloseState}
                        placeholder="Sort By Location"
                        fluid
                        selection
                        options={this.state.locations}
                        value={this.state.locationSelected}
                      />
                    </div>
                  </div>
                  <div className="search-row-whaka-check"></div>
                  <div className="search-row-two">
                    <div className="column col-1">
                      <div style={containerStyle}>
                        {this.state.makesSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Make"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}
                        <Dropdown
                          key="make"
                          clearable
                          open={this.state.makesOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Make"
                          fluid
                          selection
                          options={this.state.makes}
                          value={this.state.makesSelected}
                        />
                      </div>
                      <div style={containerStyle}>
                        {this.state.modelsSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Model"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}
                        <Dropdown
                          key="models"
                          clearable
                          open={this.state.modelsOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Model"
                          fluid
                          selection
                          options={this.state.models}
                          value={this.state.modelsSelected}
                        />
                      </div>
                      <div style={containerStyle}>
                        {this.state.conditionSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Condition"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}

                        <Dropdown
                          key="conditions"
                          clearable
                          open={this.state.conditionOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Condition"
                          fluid
                          selection
                          options={this.state.condition}
                          value={this.state.conditionSelected}
                        />
                      </div>
                    </div>
                    <div className="column col-2">
                      <div style={containerStyle}>
                        {this.state.transmissonTypesSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Transmisson"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}
                        <Dropdown
                          key="transmission"
                          clearable
                          open={this.state.transmissionOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Transmisson"
                          fluid
                          selection
                          options={this.state.transmissonTypes}
                          value={this.state.transmissonTypesSelected}
                        />
                      </div>
                      <div style={containerStyle}>
                        {this.state.fuelTypeSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Fuel Type"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}
                        <Dropdown
                          key="fuelType"
                          clearable
                          open={this.state.fuelTypeOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Fuel Type"
                          fluid
                          selection
                          options={this.state.fuelType}
                          value={this.state.fuelTypeSelected}
                          selection
                        />
                      </div>
                      <div style={containerStyle}>
                        {this.state.bodyStyleSelected ? (
                          <Icon
                            link
                            name="close"
                            placeholder="Body Style"
                            style={iconStyle}
                            onClick={this.handleClear}
                          />
                        ) : null}
                        <Dropdown
                          key="bodyStyle"
                          open={this.state.bodyStyleOpen}
                          onBlur={this.handleCloseState}
                          onFocus={this.handleOpenState}
                          onClick={this.handleOpenState}
                          onChange={this.handleCloseState}
                          placeholder="Body Style"
                          fluid
                          selection
                          options={this.state.bodyStyle}
                          value={this.state.bodyStyleSelected}
                          selection
                        />
                      </div>
                    </div>
                  </div>
                  <div className="search-row-three">
                    <div className="range-input-wrapper">
                      <p>
                        Price{" "}
                        {`$${this.state.price.min} - $${this.state.price.max}`}
                      </p>
                      <InputRange
                        maxValue={parseInt(
                          _.max(this.props.vehicleData, (v) =>
                            parseInt(v.price)
                          ).price
                        )}
                        minValue={parseInt(2995)}
                        formatLabel={(value) => ``}
                        step={1000}
                        value={this.state.price}
                        onChange={(price) => {
                          return this.setState({ price });
                        }}
                      />
                    </div>
                    <div className="range-input-wrapper">
                      <p>
                        Year {`${this.state.year.min} - ${this.state.year.max}`}
                      </p>
                      <InputRange
                        maxValue={parseInt(
                          _.max(this.props.vehicleData, (v) => parseInt(v.year))
                            .year
                        )}
                        minValue={parseInt(
                          _.min(this.props.vehicleData, (v) => parseInt(v.year))
                            .year
                        )}
                        formatLabel={(value) => ``}
                        value={this.state.year}
                        onChange={(year) => this.setState({ year })}
                      />
                    </div>

                    {false ? (
                      <div className="range-input-wrapper">
                        <p>
                          Mileage{" "}
                          {`${this.state.mileage.min}km - ${this.state.mileage.max}km`}
                        </p>
                        <InputRange
                          draggableTrack={true}
                          onChangeStart={() => {}}
                          maxValue={parseInt(
                            _.max(this.props.vehicleData, (v) =>
                              parseInt(v.mileage)
                            ).mileage
                          )}
                          minValue={0}
                          step={5000}
                          formatLabel={(value) => ``}
                          value={this.state.mileage}
                          onChange={(mileage) => this.setState({ mileage })}
                        />
                      </div>
                    ) : (
                      <div className="range-input-wrapper">
                        <p>
                          Finance{" "}
                          {`$${this.state.finance.min} - $${this.state.finance.max}`}{" "}
                          p/wk
                        </p>
                        <InputRange
                          draggableTrack={true}
                          onChangeStart={() => {}}
                          maxValue={600}
                          minValue={10}
                          step={10}
                          formatLabel={(value) => ``}
                          value={this.state.finance}
                          onChange={(finance) => {
                            if (finance.min < 0) {
                              return this.setState({
                                finance: { min: 0, max: finance.max },
                              });
                            }
                            return this.setState({ finance });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="search-row-four">
                    <Button negative onClick={this.search}>
                      Search Vehicles
                    </Button>
                  </div>
                </div>
              );
            }
          }}
        </MediaQuery>
      </div>
    );
  }
}

export default Search;
