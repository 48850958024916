import React from 'react'
import './listings.scss';
import 'react-input-range/lib/css/index.css';
import Listing from './../Listings/Listing';
import * as _ from 'underscore';


class SimilarListings extends React.Component {

  constructor(props){
    super(props);
  }
  componentWillMount(){
    this.setState({
      filteredVehicleData: this.props.vehicleData,
      vehicleData: this.props.vehicleData
    })
  }
  componentDidMount(){
    this.searchListings();
  }
  searchListings = async ()=>{
    let vehicle_id = this.getParameterByName('vehicle_id');
    let searchQuery = await JSON.parse(sessionStorage.getItem('NZDNiccarsSearchQuery'));

    let searchObject = {};

    if(vehicle_id && vehicle_id!==""){
      searchObject.id = parseInt(vehicle_id)
    }

    //first level of search
    let filterdList = _.where(this.props.vehicleData, searchObject);

    filterdList = filterdList.slice(0, 1);
    if(!vehicle_id || vehicle_id==""){
      filterdList = [];
    }

    this.setState({
      filteredVehicleData: filterdList
    })
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  render(){
    return(
      <div className="sidebar-listing-page">
        <div className="listings-wrapper-wrapper">
          <div className="listings-wrapper">
            {
              this.state.filteredVehicleData.map((vehicle, key)=>{
                return <Listing key={key} vehicle={vehicle} dealOfTheWeek={this.props.dealOfTheWeek}/>
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SimilarListings;
