import React from "react";
import "./listings.scss";

import "react-input-range/lib/css/index.css";
import Search from "./../Search/Search";
import Listing from "./Listing";
import * as _ from "underscore";
import { Dropdown, Input, Button, Accordion, Icon } from "semantic-ui-react";
class Listings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nofinance: false,
      sorts: [
        { text: "Best Match", value: "best_match" },
        { text: "Highest Price", value: "highest_price" },
        { text: "Lowest Price", value: "lowest_price" },
        { text: "Latest Listings", value: "latest_listings" },
        { text: "Oldest Listings", value: "oldest_listings" },
      ],
      sortsSelected: "best_match",

      initialLoadCount: 12, // Number of listings to load initially jed
      loadIncrement: 12, // Number of listings to load on each scroll jed
      displayedListingsCount: 12, // Current number of listings displayed jed
      filteredVehicleData: [],
      loading: false,
    };

    this.onUnload = this.onUnload.bind(this);

    this.handleScroll = this.handleScroll.bind(this);
    this.loadMoreListings = this.loadMoreListings.bind(this);
  }

  async onUnload(event) {
    await sessionStorage.removeItem("NZDNiccarsSearchQuery");
    return undefined;
  }

  componentWillMount() {
    let vehicleData = _.sortBy(this.props.vehicleData, function (v) {
      return v.id;
    });
    this.setState({
      filteredVehicleData: vehicleData,
      vehicleData: this.props.vehicleData,
    });
  }
  async componentDidMount() {
    let condition = this.getParameterByName("condition");
    let location = this.getParameterByName("location");
    let make = this.getParameterByName("make");
    let model = this.getParameterByName("model");
    let minYear = this.getParameterByName("minYear");
    let maxYear = this.getParameterByName("maxYear");

    let searchQuery =
      (await JSON.parse(sessionStorage.getItem("NZDNiccarsSearchQuery"))) || {};

    if (
      searchQuery &&
      (Object.keys(searchQuery).length == undefined ||
        Object.keys(searchQuery).length == 0)
    ) {
      searchQuery = {
        searchVehicles: "",
        locationSelected: "",
        makesSelected: "",
        modelsSelected: "",
        conditionSelected: "",
        transmissonTypesSelected: "",
        fuelTypeSelected: "",
        driveModeSelected: "",
        bodyStyleSelected: "",
        year: {
          min: parseInt(
            _.min(this.props.vehicleData, (v) => parseInt(v.year)).year
          ),
          max: parseInt(
            _.max(this.props.vehicleData, (v) => parseInt(v.year)).year
          ),
        },
        mileage: {
          min: parseInt(
            _.min(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
          ),
          max: parseInt(
            _.max(this.props.vehicleData, (v) => parseInt(v.mileage)).mileage
          ),
        },
        price: {
          min: parseInt(2995),
          max: parseInt(
            _.max(this.props.vehicleData, (v) => parseInt(v.price)).price
          ),
        },
      };
    }

    let stateObject = Object.assign({}, this.state);

    if (make) {
      searchQuery.makesSelected = make;
      stateObject = Object.assign(
        {},
        { ...stateObject },
        { makesSelected: make }
      );
    }
    if (condition) {
      searchQuery.conditionSelected = condition;
      stateObject = Object.assign(
        {},
        { ...stateObject },
        { conditionSelected: condition }
      );
    }

    if (model) {
      searchQuery.modelsSelected = model;
      stateObject = Object.assign(
        {},
        { ...stateObject },
        { modelsSelected: model }
      );
    }

    if (minYear || maxYear) {
      stateObject = Object.assign(
        {},
        { ...stateObject },
        {
          year: {
            min: parseInt(
              _.min(this.props.vehicleData, (v) => parseInt(v.year)).year
            ),
            max: parseInt(
              _.max(this.props.vehicleData, (v) => parseInt(v.year)).year
            ),
          },
        }
      );
    }
    if (minYear && searchQuery.year) {
      searchQuery.year.min = parseInt(minYear);

      stateObject = Object.assign(
        {},
        { ...stateObject },
        { year: { ...searchQuery.year, min: parseInt(minYear) } }
      );
    }

    if (maxYear && searchQuery.year) {
      searchQuery.year.max = parseInt(maxYear);
      stateObject = Object.assign(
        {},
        { ...stateObject },
        { year: { ...searchQuery.year, max: parseInt(maxYear) } }
      );
    }

    this.setState(stateObject);

    await sessionStorage.setItem(
      "NZDNiccarsSearchQuery",
      JSON.stringify(searchQuery)
    );

    searchQuery =
      (await JSON.parse(sessionStorage.getItem("NZDNiccarsSearchQuery"))) || {};

    this.searchListings();
    window.addEventListener("scroll", this.handleScroll); //jed
  }

  componentWillUnmount() { //jed
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const { displayedListingsCount, filteredVehicleData, loadIncrement, loading } = this.state;
    const lastIndex = displayedListingsCount - 1;
    const totalListings = filteredVehicleData.length;

    const listingsWrapper = document.querySelector(".listings-wrapper");
    if (listingsWrapper && !loading) {
      const lastListing = listingsWrapper.lastElementChild;
      const lastListingOffset = lastListing.offsetTop + lastListing.clientHeight;
      const pageOffset = window.pageYOffset + window.innerHeight;

      if (pageOffset > lastListingOffset && lastIndex < totalListings - 1) {
        this.loadMoreListings();
      }
    }
  }
  
  async loadMoreListings() {
    this.setState({ loading: true });
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate loading delay
    this.setState((prevState) => ({
      displayedListingsCount: Math.min(prevState.displayedListingsCount + this.state.loadIncrement, prevState.filteredVehicleData.length),
      loading: false,
    }));
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  searchListings = async () => {
    let searchQuery = await JSON.parse(
      sessionStorage.getItem("NZDNiccarsSearchQuery")
    );

    let searchObject = {};

    if (searchQuery.makesSelected && searchQuery.makesSelected !== "") {
      searchObject.make = searchQuery.makesSelected;
    }

    if (searchQuery.modelsSelected && searchQuery.modelsSelected !== "") {
      searchObject.model = searchQuery.modelsSelected;
    }

    if (searchQuery.conditionSelected && searchQuery.conditionSelected !== "") {
      searchObject.condition = searchQuery.conditionSelected;


    }

    if (
      searchQuery.transmissonTypesSelected &&
      searchQuery.transmissonTypesSelected !== ""
    ) {
      searchObject.transmission = searchQuery.transmissonTypesSelected;
    }

    if (searchQuery.fuelType && searchQuery.fuelType !== "") {
      searchObject.fuel = searchQuery.fuelType;
    }

    if (searchQuery.driveMode && searchQuery.driveMode !== "") {
      searchObject.drivetrain = searchQuery.driveMode;
    }
    if (searchQuery.bodyStyle && searchQuery.bodyStyle !== "") {
      searchObject.bodyStyle = searchQuery.bodyStyle;
    }

    //first level of search
    let filterdList = _.where(this.props.vehicleData, searchObject);

    const newSearchObject = { ...searchObject, condition: "Demonstrators" };
    let filterdList2 = _.where(this.props.vehicleData, newSearchObject);
 
    let filterdListWithDemoVehicles = _.union(filterdList, filterdList2);
    // searching for location
    if (searchQuery.locationSelected && searchQuery.locationSelected != "") {
      filterdListWithDemoVehicles = _.filter(
        filterdListWithDemoVehicles,
        function (item, index) {
          var whaka = false;
          if (searchQuery.locationSelected === "Whakatane") {
            whaka = true;
            if (
              _.contains(searchQuery.locationExtraSelected, "The Hub Whakatane")
            ) {
              searchQuery.locationExtraSelected.push("The Hub");
            }

            if (
              _.contains(searchQuery.locationExtraSelected, "Niccars Whakatane")
            ) {
              searchQuery.locationExtraSelected.push("Whakatane");
            }
          }

          if (whaka && searchQuery.locationExtraSelected) {
            return _.contains(searchQuery.locationExtraSelected, item.location);
          } else {
            return item.location == searchQuery.locationSelected;
          }
        }

        );
    }

    //searching for price range
    if (
      searchQuery &&
      searchQuery.price &&
      searchQuery.price.min &&
      searchQuery.price.max
    ) {
      filterdListWithDemoVehicles = _.filter(
        filterdListWithDemoVehicles,
        (v) => {
          return (
            parseInt(v.price) >= parseInt(searchQuery.price.min) &&
            parseInt(v.price) <= parseInt(searchQuery.price.max)
          );
        }
      );
    }
    //searching for year range
    if (
      searchQuery &&
      searchQuery.year &&
      searchQuery.year.min &&
      searchQuery.year.max
    ) {
      filterdListWithDemoVehicles = _.filter(
        filterdListWithDemoVehicles,
        (v) => {
          return (
            parseInt(v.year) >= parseInt(searchQuery.year.min) &&
            parseInt(v.year) <= parseInt(searchQuery.year.max)
          );
        }
      );
    }

    //searching for mileage
    if (
      searchQuery &&
      searchQuery.mileage &&
      searchQuery.mileage.min &&
      searchQuery.mileage.max
    ) {
      filterdListWithDemoVehicles = _.filter(
        filterdListWithDemoVehicles,
        (v) => {
          return (
            parseInt(v.mileage) >= parseInt(searchQuery.mileage.min) &&
            parseInt(v.mileage) <= parseInt(searchQuery.mileage.max)
          );
        }
      );
    }

    //searching for finance
    if (
      searchQuery &&
      searchQuery.finance &&
      searchQuery.finance.min &&
      searchQuery.finance.max
    ) {
      filterdListWithDemoVehicles = _.filter(
        filterdListWithDemoVehicles,
        (v) => {
          return (
            parseInt(v.finance) >= parseInt(searchQuery.finance.min) &&
            parseInt(v.finance) <= parseInt(searchQuery.finance.max)
          );
        }
      );
    }

    //searching for searchtext
    if (searchQuery.searchVehicles && searchQuery.searchVehicles !== "") {
      // filterdListWithDemoVehicles = _.filter(filterdListWithDemoVehicles, (v)=>{if(v.name.toLowerCase().search(searchQuery.searchVehicles.toLowerCase()) >=0 ){return true;}else{return false} })
      filterdListWithDemoVehicles = filterdListWithDemoVehicles.filter((v) => {
        if (
          v.name
            .toLowerCase()
            .search(searchQuery.searchVehicles.toLowerCase()) >= 0
        ) {
          return v;
        } else {
          return false;
        }
      });
    }

    this.setState({

      displayedListingsCount: 12,

      filteredVehicleData: filterdListWithDemoVehicles.sort(function (a, b) {
        var order = document.body.getAttribute("data-used-order");

        if (order && order === "desc") {
          return a.id > b.id ? -1 : 1;
        } else {
          return a.id < b.id ? -1 : 1;
        }
      }),
    });
  };

  handleCloseState = (e, { value }) => {
    if (e && value) {
      this.setState({
        sortsSelected: value,
      });
    }
  };



  sortVehicleData = ({ vehicleData }) => {
    switch (this.state.sortsSelected) {
      case "highest_price":
        return _.sortBy(vehicleData, function (v) {
          return v.price * -1;
        });
      case "lowest_price":
        return _.sortBy(vehicleData, function (v) {
          return parseInt(v.price);
        });
      case "latest_listings":
        return _.sortBy(vehicleData, function (v) {
          return v.date * -1;
        });
      case "oldest_listings":
        return _.sortBy(vehicleData, function (v) {
          return v.date;
        });
      case "best_match":
      default:
        return vehicleData;
    }
  };


  render() {
    // let vehicleData = this.sortVehicleData({
    //   vehicleData: this.state.filteredVehicleData,
    // });

    let vehicleData = this.sortVehicleData({
      vehicleData: this.state.filteredVehicleData,
    }).slice(0, this.state.displayedListingsCount);

    return (
      <div className="listing-page">
        <Search
          makesSelected={this.state.makesSelected || ""}
          conditionSelected={this.state.conditionSelected || ""}
          modelsSelected={this.state.modelsSelected || ""}
          year={this.state.year}
          searchListings={this.searchListings}
          vehicleData={this.props.vehicleData}
          numberOfResults={this.state.filteredVehicleData.length}
        />
        <div className="listings-wrapper-wrapper">
          <div className="listings-sort-dropdown NZD2">

            <div className="listings-sort-dropdown-col-1 ss">
              <Dropdown
                key="sort"
                onChange={this.handleCloseState}
                placeholder="Sort"
                fluid
                selection
                options={this.state.sorts}
                value={this.state.sortsSelected}
              />
            </div>
            <div className="listings-sort-dropdown-col-2">&nbsp;</div>
          </div>
          <div className="listings-wrapper">

            {vehicleData.map((vehicle, key) => {
              if (key <= 7) {
                return (
                  <Listing
                    key={key}
                    vehicle={vehicle}
                    dealOfTheWeek={this.props.dealOfTheWeek}
                    dealOfTheWeekOne={this.props.dealOfTheWeekOne}
                    dealOfTheWeekTwo={this.props.dealOfTheWeekTwo}
                    dealOfTheWeekThree={this.props.dealOfTheWeekThree}
                    dealOfTheWeekFour={this.props.dealOfTheWeekFour}
                    dealOfTheWeekFive={this.props.dealOfTheWeekFive}
                    dealOfTheWeekSix={this.props.dealOfTheWeekSix}
                    dealOfTheWeekSeven={this.props.dealOfTheWeekSeven}
                    dealOfTheWeekEight={this.props.dealOfTheWeekEight}
                    dealOfTheWeekNine={this.props.dealOfTheWeekNine}
                    dealOfTheWeekTen={this.props.dealOfTheWeekTen}
                    dealOfTheWeekEleven={this.props.dealOfTheWeekEleven}
                    dealOfTheWeekTwele={this.props.dealOfTheWeekTwele}
                    dealOfTheWeekThirteen={this.props.dealOfTheWeekThirteen}
                    dealOfTheWeekFourteen={this.props.dealOfTheWeekFourteen}
                    dealOfTheWeekFifteen={this.props.dealOfTheWeekFifteen}
                    dealOfTheWeekSixteen={this.props.dealOfTheWeekSixteen}
                    dealOfTheWeekSeventeen={this.props.dealOfTheWeekSeventeen}
                    dealOfTheWeekEighteen={this.props.dealOfTheWeekEighteen}
                    dealOfTheWeekNineteen={this.props.dealOfTheWeekNineteen}
                    dealOfTheWeekTwenty={this.props.dealOfTheWeekTwenty}
                    dealOfTheWeekTwentyone={this.props.dealOfTheWeekTwentyone}
                    dealOfTheWeekTwentytwo={this.props.dealOfTheWeekTwentytwo}
                    dealOfTheWeekTwentythree={
                      this.props.dealOfTheWeekTwentythree
                    }
                    dealOfTheWeekTwentyfour={this.props.dealOfTheWeekTwentyfour}
                    dealOfTheWeekTwentyfive={this.props.dealOfTheWeekTwentyfive}
                    dealOfTheWeekTwentysix={this.props.dealOfTheWeekTwentysix}
                    dealOfTheWeekTwentyseven={
                      this.props.dealOfTheWeekTwentyseven
                    }
                    dealOfTheWeekTwentyeight={
                      this.props.dealOfTheWeekTwentyeight
                    }
                    dealOfTheWeekTwentynine={this.props.dealOfTheWeekTwentynine}
                    dealOfTheWeekThirty={this.props.dealOfTheWeekThirty}
                    dealOfTheWeekOneTitle={this.props.dealOfTheWeekOneTitle}
                    dealOfTheWeekOneTitle={this.props.dealOfTheWeekOneTitle}
                    dealOfTheWeekTwoTitle={this.props.dealOfTheWeekTwoTitle}
                    dealOfTheWeekThreeTitle={this.props.dealOfTheWeekThreeTitle}
                    dealOfTheWeekFourTitle={this.props.dealOfTheWeekFourTitle}
                    dealOfTheWeekFiveTitle={this.props.dealOfTheWeekFiveTitle}
                    dealOfTheWeekSixTitle={this.props.dealOfTheWeekSixTitle}
                    dealOfTheWeekSevenTitle={this.props.dealOfTheWeekSevenTitle}
                    dealOfTheWeekEightTitle={this.props.dealOfTheWeekEightTitle}
                    dealOfTheWeekNineTitle={this.props.dealOfTheWeekNineTitle}
                    dealOfTheWeekTenTitle={this.props.dealOfTheWeekTenTitle}
                    dealOfTheWeekElevenTitle={
                      this.props.dealOfTheWeekElevenTitle
                    }
                    dealOfTheWeekTweleTitle={this.props.dealOfTheWeekTweleTitle}
                    dealOfTheWeekThirteenTitle={
                      this.props.dealOfTheWeekThirteenTitle
                    }
                    dealOfTheWeekFourteenTitle={
                      this.props.dealOfTheWeekFourteenTitle
                    }
                    dealOfTheWeekFifteenTitle={
                      this.props.dealOfTheWeekFifteenTitle
                    }
                    dealOfTheWeekSixteenTitle={
                      this.props.dealOfTheWeekSixteenTitle
                    }
                    dealOfTheWeekSeventeenTitle={
                      this.props.dealOfTheWeekSeventeenTitle
                    }
                    dealOfTheWeekEighteenTitle={
                      this.props.dealOfTheWeekEighteenTitle
                    }
                    dealOfTheWeekNineteenTitle={
                      this.props.dealOfTheWeekNineteenTitle
                    }
                    dealOfTheWeekTwentyTitle={
                      this.props.dealOfTheWeekTwentyTitle
                    }
                    dealOfTheWeekTwentyoneTitle={
                      this.props.dealOfTheWeekTwentyoneTitle
                    }
                    dealOfTheWeekTwentytwoTitle={
                      this.props.dealOfTheWeekTwentytwoTitle
                    }
                    dealOfTheWeekTwentythreeTitle={
                      this.props.dealOfTheWeekTwentythreeTitle
                    }
                    dealOfTheWeekTwentyfourTitle={
                      this.props.dealOfTheWeekTwentyfourTitle
                    }
                    dealOfTheWeekTwentyfiveTitle={
                      this.props.dealOfTheWeekTwentyfiveTitle
                    }
                    dealOfTheWeekTwentysixTitle={
                      this.props.dealOfTheWeekTwentysixTitle
                    }
                    dealOfTheWeekTwentysevenTitle={
                      this.props.dealOfTheWeekTwentysevenTitle
                    }
                    dealOfTheWeekTwentyeightTitle={
                      this.props.dealOfTheWeekTwentyeightTitle
                    }
                    dealOfTheWeekTwentynineTitle={
                      this.props.dealOfTheWeekTwentynineTitle
                    }
                    dealOfTheWeekThirtyTitle={
                      this.props.dealOfTheWeekThirtyTitle
                    }
                    nofinance={this.state.nofinance}
                  />
                );
              }
            })}
            <div className="promo-block sss">
              <a href={this.props.promoBlockLink} target="_blank">
                <img src={`${this.props.promoBlock}`} />
              </a>
            </div>
            {vehicleData.map((vehicle, key) => {
              if (key > 7) {
                return (
                  <Listing
                    key={key}
                    vehicle={vehicle}
                    dealOfTheWeek={this.props.dealOfTheWeek}
                    dealOfTheWeekOne={this.props.dealOfTheWeekOne}
                    dealOfTheWeekTwo={this.props.dealOfTheWeekTwo}
                    dealOfTheWeekThree={this.props.dealOfTheWeekThree}
                    dealOfTheWeekFour={this.props.dealOfTheWeekFour}
                    dealOfTheWeekFive={this.props.dealOfTheWeekFive}
                    dealOfTheWeekSix={this.props.dealOfTheWeekSix}
                    dealOfTheWeekSeven={this.props.dealOfTheWeekSeven}
                    dealOfTheWeekEight={this.props.dealOfTheWeekEight}
                    dealOfTheWeekNine={this.props.dealOfTheWeekNine}
                    dealOfTheWeekTen={this.props.dealOfTheWeekTen}
                    dealOfTheWeekEleven={this.props.dealOfTheWeekEleven}
                    dealOfTheWeekTwele={this.props.dealOfTheWeekTwele}
                    dealOfTheWeekThirteen={this.props.dealOfTheWeekThirteen}
                    dealOfTheWeekFourteen={this.props.dealOfTheWeekFourteen}
                    dealOfTheWeekFifteen={this.props.dealOfTheWeekFifteen}
                    dealOfTheWeekSixteen={this.props.dealOfTheWeekSixteen}
                    dealOfTheWeekSeventeen={this.props.dealOfTheWeekSeventeen}
                    dealOfTheWeekEighteen={this.props.dealOfTheWeekEighteen}
                    dealOfTheWeekNineteen={this.props.dealOfTheWeekNineteen}
                    dealOfTheWeekTwenty={this.props.dealOfTheWeekTwenty}
                    dealOfTheWeekTwentyone={this.props.dealOfTheWeekTwentyone}
                    dealOfTheWeekTwentytwo={this.props.dealOfTheWeekTwentytwo}
                    dealOfTheWeekTwentythree={
                      this.props.dealOfTheWeekTwentythree
                    }
                    dealOfTheWeekTwentyfour={this.props.dealOfTheWeekTwentyfour}
                    dealOfTheWeekTwentyfive={this.props.dealOfTheWeekTwentyfive}
                    dealOfTheWeekTwentysix={this.props.dealOfTheWeekTwentysix}
                    dealOfTheWeekTwentyseven={
                      this.props.dealOfTheWeekTwentyseven
                    }
                    dealOfTheWeekTwentyeight={
                      this.props.dealOfTheWeekTwentyeight
                    }
                    dealOfTheWeekTwentynine={this.props.dealOfTheWeekTwentynine}
                    dealOfTheWeekThirty={this.props.dealOfTheWeekThirty}
                    dealOfTheWeekOneTitle={this.props.dealOfTheWeekOneTitle}
                    dealOfTheWeekOneTitle={this.props.dealOfTheWeekOneTitle}
                    dealOfTheWeekTwoTitle={this.props.dealOfTheWeekTwoTitle}
                    dealOfTheWeekThreeTitle={this.props.dealOfTheWeekThreeTitle}
                    dealOfTheWeekFourTitle={this.props.dealOfTheWeekFourTitle}
                    dealOfTheWeekFiveTitle={this.props.dealOfTheWeekFiveTitle}
                    dealOfTheWeekSixTitle={this.props.dealOfTheWeekSixTitle}
                    dealOfTheWeekSevenTitle={this.props.dealOfTheWeekSevenTitle}
                    dealOfTheWeekEightTitle={this.props.dealOfTheWeekEightTitle}
                    dealOfTheWeekNineTitle={this.props.dealOfTheWeekNineTitle}
                    dealOfTheWeekTenTitle={this.props.dealOfTheWeekTenTitle}
                    dealOfTheWeekElevenTitle={
                      this.props.dealOfTheWeekElevenTitle
                    }
                    dealOfTheWeekTweleTitle={this.props.dealOfTheWeekTweleTitle}
                    dealOfTheWeekThirteenTitle={
                      this.props.dealOfTheWeekThirteenTitle
                    }
                    dealOfTheWeekFourteenTitle={
                      this.props.dealOfTheWeekFourteenTitle
                    }
                    dealOfTheWeekFifteenTitle={
                      this.props.dealOfTheWeekFifteenTitle
                    }
                    dealOfTheWeekSixteenTitle={
                      this.props.dealOfTheWeekSixteenTitle
                    }
                    dealOfTheWeekSeventeenTitle={
                      this.props.dealOfTheWeekSeventeenTitle
                    }
                    dealOfTheWeekEighteenTitle={
                      this.props.dealOfTheWeekEighteenTitle
                    }
                    dealOfTheWeekNineteenTitle={
                      this.props.dealOfTheWeekNineteenTitle
                    }
                    dealOfTheWeekTwentyTitle={
                      this.props.dealOfTheWeekTwentyTitle
                    }
                    dealOfTheWeekTwentyoneTitle={
                      this.props.dealOfTheWeekTwentyoneTitle
                    }
                    dealOfTheWeekTwentytwoTitle={
                      this.props.dealOfTheWeekTwentytwoTitle
                    }
                    dealOfTheWeekTwentythreeTitle={
                      this.props.dealOfTheWeekTwentythreeTitle
                    }
                    dealOfTheWeekTwentyfourTitle={
                      this.props.dealOfTheWeekTwentyfourTitle
                    }
                    dealOfTheWeekTwentyfiveTitle={
                      this.props.dealOfTheWeekTwentyfiveTitle
                    }
                    dealOfTheWeekTwentysixTitle={
                      this.props.dealOfTheWeekTwentysixTitle
                    }
                    dealOfTheWeekTwentysevenTitle={
                      this.props.dealOfTheWeekTwentysevenTitle
                    }
                    dealOfTheWeekTwentyeightTitle={
                      this.props.dealOfTheWeekTwentyeightTitle
                    }
                    dealOfTheWeekTwentynineTitle={
                      this.props.dealOfTheWeekTwentynineTitle
                    }
                    dealOfTheWeekThirtyTitle={
                      this.props.dealOfTheWeekThirtyTitle
                    }
                    nofinance={this.state.nofinance}
                  />
                );
              }
            })}
          </div>
          {this.state.loading && (
              <div style={{ textAlign: "center", padding: "20px", display: "block" }}>
                <center>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ margin: "auto", background: "none", display: "block" }}
                    width="50px"
                    height="50px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g transform="translate(50,50)">
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="7.14286" fill="#143a82" transform="scale(1.03036 1.03036)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.9464285714285714s"
                            values="1.03036 1.03036;1.17606 1.17606;1.03036 1.03036"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="14.2857" fill="#143a82" transform="scale(1.06071 1.06071)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.8928571428571428s"
                            values="1.06071 1.06071;1.20711 1.20711;1.06071 1.06071"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="21.4286" fill="#143a82" transform="scale(1.09107 1.09107)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.8392857142857143s"
                            values="1.09107 1.09107;1.24311 1.24311;1.09107 1.09107"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="28.5714" fill="#143a82" transform="scale(1.12143 1.12143)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.7857142857142857s"
                            values="1.12143 1.12143;1.27821 1.27821;1.12143 1.12143"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="35.7143" fill="#143a82" transform="scale(1.14978 1.14978)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.7321428571428571s"
                            values="1.14978 1.14978;1.31291 1.31291;1.14978 1.14978"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="42.8571" fill="#143a82" transform="scale(1.17606 1.17606)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.6785714285714286s"
                            values="1.17606 1.17606;1.34761 1.34761;1.17606 1.17606"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                      <g transform="scale(0.7)">
                        <circle cx="0" cy="0" r="50" fill="#143a82" transform="scale(1.20711 1.20711)">
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.625s"
                            values="1.20711 1.20711;1.38268 1.38268;1.20711 1.20711"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                          />
                          <animate attributeName="fill-opacity" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" values="0;1;1" />
                        </circle>
                      </g>
                    </g>
                  </svg>
                </center>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default Listings;