import React from "react";
// import './style.scss';
import ReactDOM from "react-dom";
import Search from "./Search/Search";
import Listings from "./Listings";
import SimilarListings from "./SimilarListings";
import SidebarListing from "./SidebarListing";
import "semantic-ui-css/semantic.min.css";
import * as _ from "underscore";

class NZDNiccars extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleData: [],
    };
  }

  getFinance(total, ratePerYear, durationInYears) {
    let depositPercentage = 20;
    let totalDeposit = (total * depositPercentage) / 100;
    let weekly = (total - totalDeposit) / 204.689184964;

    return Math.round(weekly);
  }

  async componentWillMount() {
    let vehicleData = _.sortBy(this.props.vehicleData, function (v) {
      return v.id;
    });

    let modifiedVehicleDataWithFinance = vehicleData.map((vehicle) => {
      if (!vehicle) {
        return vehicle; // Skip if vehicle is empty (null or undefined)
      }
      
      return {
        ...vehicle,
        finance: this.getFinance(vehicle.price, 9.95, 5),
      };
    });

    await this.setState({
      vehicleData: modifiedVehicleDataWithFinance,
    });
  }

  render() {
    let uniqueMakes = _.uniq(this.state.vehicleData, "make").map((v) => v.make);

    return this.props.layout === "search" ? (
      <div className="nzd-niccars-search">
        <Search
          vehicleData={this.state.vehicleData}
          openAccordianOnFirstLoad={true}
        />
      </div>
    ) : this.props.layout === "listings" ? (
      <div className="nzd-niccars-listings">
        <Listings
          vehicleData={this.state.vehicleData}
          dealOfTheWeek={this.props.dealOfTheWeek}
          dealOfTheWeekOne={this.props.dealOfTheWeekOne}
          dealOfTheWeekTwo={this.props.dealOfTheWeekTwo}
          dealOfTheWeekThree={this.props.dealOfTheWeekThree}
          dealOfTheWeekFour={this.props.dealOfTheWeekFour}
          dealOfTheWeekFive={this.props.dealOfTheWeekFive}
          dealOfTheWeekSix={this.props.dealOfTheWeekSix}
          dealOfTheWeekSeven={this.props.dealOfTheWeekSeven}
          dealOfTheWeekEight={this.props.dealOfTheWeekEight}
          dealOfTheWeekNine={this.props.dealOfTheWeekNine}
          dealOfTheWeekTen={this.props.dealOfTheWeekTen}
          dealOfTheWeekEleven={this.props.dealOfTheWeekEleven}
          dealOfTheWeekTwele={this.props.dealOfTheWeekTwele}
          dealOfTheWeekThirteen={this.props.dealOfTheWeekThirteen}
          dealOfTheWeekFourteen={this.props.dealOfTheWeekFourteen}
          dealOfTheWeekFifteen={this.props.dealOfTheWeekFifteen}
          dealOfTheWeekSixteen={this.props.dealOfTheWeekSixteen}
          dealOfTheWeekSeventeen={this.props.dealOfTheWeekSeventeen}
          dealOfTheWeekEighteen={this.props.dealOfTheWeekEighteen}
          dealOfTheWeekNineteen={this.props.dealOfTheWeekNineteen}
          dealOfTheWeekTwenty={this.props.dealOfTheWeekTwenty}
          dealOfTheWeekTwentyone={this.props.dealOfTheWeekTwentyone}
          dealOfTheWeekTwentytwo={this.props.dealOfTheWeekTwentytwo}
          dealOfTheWeekTwentythree={this.props.dealOfTheWeekTwentythree}
          dealOfTheWeekTwentyfour={this.props.dealOfTheWeekTwentyfour}
          dealOfTheWeekTwentyfive={this.props.dealOfTheWeekTwentyfive}
          dealOfTheWeekTwentysix={this.props.dealOfTheWeekTwentysix}
          dealOfTheWeekTwentyseven={this.props.dealOfTheWeekTwentyseven}
          dealOfTheWeekTwentyeight={this.props.dealOfTheWeekTwentyeight}
          dealOfTheWeekTwentynine={this.props.dealOfTheWeekTwentynine}
          dealOfTheWeekThirty={this.props.dealOfTheWeekThirty}
          dealOfTheWeekOneTitle={this.props.dealOfTheWeekOneTitle}
          dealOfTheWeekTwoTitle={this.props.dealOfTheWeekTwoTitle}
          dealOfTheWeekThreeTitle={this.props.dealOfTheWeekThreeTitle}
          dealOfTheWeekFourTitle={this.props.dealOfTheWeekFourTitle}
          dealOfTheWeekFiveTitle={this.props.dealOfTheWeekFiveTitle}
          dealOfTheWeekSixTitle={this.props.dealOfTheWeekSixTitle}
          dealOfTheWeekSevenTitle={this.props.dealOfTheWeekSevenTitle}
          dealOfTheWeekEightTitle={this.props.dealOfTheWeekEightTitle}
          dealOfTheWeekNineTitle={this.props.dealOfTheWeekNineTitle}
          dealOfTheWeekTenTitle={this.props.dealOfTheWeekTenTitle}
          dealOfTheWeekElevenTitle={this.props.dealOfTheWeekElevenTitle}
          dealOfTheWeekTweleTitle={this.props.dealOfTheWeekTweleTitle}
          dealOfTheWeekThirteenTitle={this.props.dealOfTheWeekThirteenTitle}
          dealOfTheWeekFourteenTitle={this.props.dealOfTheWeekFourteenTitle}
          dealOfTheWeekFifteenTitle={this.props.dealOfTheWeekFifteenTitle}
          dealOfTheWeekSixteenTitle={this.props.dealOfTheWeekSixteenTitle}
          dealOfTheWeekSeventeenTitle={this.props.dealOfTheWeekSeventeenTitle}
          dealOfTheWeekEighteenTitle={this.props.dealOfTheWeekEighteenTitle}
          dealOfTheWeekNineteenTitle={this.props.dealOfTheWeekNineteenTitle}
          dealOfTheWeekTwentyTitle={this.props.dealOfTheWeekTwentyTitle}
          dealOfTheWeekTwentyoneTitle={this.props.dealOfTheWeekTwentyoneTitle}
          dealOfTheWeekTwentytwoTitle={this.props.dealOfTheWeekTwentytwoTitle}
          dealOfTheWeekTwentythreeTitle={
            this.props.dealOfTheWeekTwentythreeTitle
          }
          dealOfTheWeekTwentyfourTitle={this.props.dealOfTheWeekTwentyfourTitle}
          dealOfTheWeekTwentyfiveTitle={this.props.dealOfTheWeekTwentyfiveTitle}
          dealOfTheWeekTwentysixTitle={this.props.dealOfTheWeekTwentysixTitle}
          dealOfTheWeekTwentysevenTitle={
            this.props.dealOfTheWeekTwentysevenTitle
          }
          dealOfTheWeekTwentyeightTitle={
            this.props.dealOfTheWeekTwentyeightTitle
          }
          dealOfTheWeekTwentynineTitle={this.props.dealOfTheWeekTwentynineTitle}
          dealOfTheWeekThirtyTitle={this.props.dealOfTheWeekThirtyTitle}
          promoBlock={this.props.promoBlock}
          promoBlockLink={this.props.promoBlockLink}
        />
      </div>
    ) : this.props.layout === "similarlistings" ? (
      <div className="nzd-niccars-similar-listings">
        <SimilarListings
          similarMake={this.props.similarMake}
          vehicleData={this.state.vehicleData}
          dealOfTheWeek={this.props.dealOfTheWeek}
        />
      </div>
    ) : this.props.layout === "sidebar-listing" ? (
      <div className="nzd-niccars-sidebar-listings">
        <SidebarListing vehicleData={this.state.vehicleData} />
      </div>
    ) : null;
  }
}

export const Component = NZDNiccars;
export function render(element, props) {
  ReactDOM.render(<Component {...props} />, element);
}

export default {
  render,
  Component,
};
