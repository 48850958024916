import React from "react";
import "./listing.scss";

class Listings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // console.log('this.props.vehicle', this.props.vehicle)
    return (
      <div
        id={
          this.props.vehicle.id == this.props.dealOfTheWeekOne ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwo ||
          this.props.vehicle.id == this.props.dealOfTheWeekThree ||
          this.props.vehicle.id == this.props.dealOfTheWeekFour ||
          this.props.vehicle.id == this.props.dealOfTheWeekFive ||
          this.props.vehicle.id == this.props.dealOfTheWeekSix ||
          this.props.vehicle.id == this.props.dealOfTheWeekSeven ||
          this.props.vehicle.id == this.props.dealOfTheWeekEight ||
          this.props.vehicle.id == this.props.dealOfTheWeekNine ||
          this.props.vehicle.id == this.props.dealOfTheWeekTen ||
          this.props.vehicle.id == this.props.dealOfTheWeekEleven ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwele ||
          this.props.vehicle.id == this.props.dealOfTheWeekThirteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekFourteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekFifteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekSixteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekSeventeen ||
          this.props.vehicle.id == this.props.dealOfTheWeekEightteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekNineteen ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwenty ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentyone ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentytwo ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentythree ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentyfour ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentyfive ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentysix ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentyseven ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentyeight ||
          this.props.vehicle.id == this.props.dealOfTheWeekTwentynine ||
          this.props.vehicle.id == this.props.dealOfTheWeekThirty
            ? "deal-of-the-week-red"
            : ""
        }
        className={
          this.props.vehicle.id == this.props.dealOfTheWeek
            ? `listing-wrapper nzd3 id-${this.props.vehicle.id}  deal-of-the-week`
            : `listing-wrapper nzd4 id-${this.props.vehicle.id}`
        }
      >
        <span
          className={
            this.props.vehicle.id == this.props.dealOfTheWeekOne ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwo ||
            this.props.vehicle.id == this.props.dealOfTheWeekThree ||
            this.props.vehicle.id == this.props.dealOfTheWeekFour ||
            this.props.vehicle.id == this.props.dealOfTheWeekFive ||
            this.props.vehicle.id == this.props.dealOfTheWeekSix ||
            this.props.vehicle.id == this.props.dealOfTheWeekSeven ||
            this.props.vehicle.id == this.props.dealOfTheWeekEight ||
            this.props.vehicle.id == this.props.dealOfTheWeekNine ||
            this.props.vehicle.id == this.props.dealOfTheWeekTen ||
            this.props.vehicle.id == this.props.dealOfTheWeekEleven ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwele ||
            this.props.vehicle.id == this.props.dealOfTheWeekThirteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekFourteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekFifteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekSixteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekSeventeen ||
            this.props.vehicle.id == this.props.dealOfTheWeekEightteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekNineteen ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwenty ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentyone ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentytwo ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentythree ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentyfour ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentyfive ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentysix ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentyseven ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentyeight ||
            this.props.vehicle.id == this.props.dealOfTheWeekTwentynine ||
            this.props.vehicle.id == this.props.dealOfTheWeekThirty
              ? "dealtitle"
              : null
          }
        >
          {this.props.vehicle.id == this.props.dealOfTheWeekOne
            ? `${this.props.dealOfTheWeekOneTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwo
            ? `${this.props.dealOfTheWeekTwoTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekThree
            ? `${this.props.dealOfTheWeekThreeTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekFour
            ? `${this.props.dealOfTheWeekFourTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekFive
            ? `${this.props.dealOfTheWeekFiveTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekSix
            ? `${this.props.dealOfTheWeekSixTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekSeven
            ? `${this.props.dealOfTheWeekSevenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekEight
            ? `${this.props.dealOfTheWeekEightTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekNine
            ? `${this.props.dealOfTheWeekNineTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTen
            ? `${this.props.dealOfTheWeekTenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekEleven
            ? `${this.props.dealOfTheWeekElevenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwele
            ? `${this.props.dealOfTheWeekTweleTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekThirteen
            ? `${this.props.dealOfTheWeekThirteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekFourteen
            ? `${this.props.dealOfTheWeekFourteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekFifteen
            ? `${this.props.dealOfTheWeekFifteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekSixteen
            ? `${this.props.dealOfTheWeekSixteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekSeventeen
            ? `${this.props.dealOfTheWeekSeventeenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekEighteen
            ? `${this.props.dealOfTheWeekEighteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekNineteen
            ? `${this.props.dealOfTheWeekNineteenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwenty
            ? `${this.props.dealOfTheWeekTwentyTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentyone
            ? `${this.props.dealOfTheWeekTwentyoneTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentytwo
            ? `${this.props.dealOfTheWeekTwentytwoTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentythree
            ? `${this.props.dealOfTheWeekTwentythreeTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentyfour
            ? `${this.props.dealOfTheWeekTwentyfourTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentyfive
            ? `${this.props.dealOfTheWeekTwentyfiveTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentysix
            ? `${this.props.dealOfTheWeekTwentysixTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentyseven
            ? `${this.props.dealOfTheWeekTwentysevenTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentyeight
            ? `${this.props.dealOfTheWeekTwentyeightTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekTwentynine
            ? `${this.props.dealOfTheWeekTwentynineTitle}`
            : ""}
          {this.props.vehicle.id == this.props.dealOfTheWeekThirty
            ? `${this.props.dealOfTheWeekThirtyTitle}`
            : ""}
        </span>
        <div className="image-section">
          {this.props.vehicle.condition === "Demonstrators"?  <div className="demo-badge">Demonstrator</div>: null}
          <a href={`${this.props.vehicle.link}`} target="_self">
			<img
              src={`${
                this.props.vehicle.thumbnail
                  ? this.props.vehicle.thumbnail[0]
                  : null
              }`}
			/>
          </a>
        </div>

        <h3 className="vehicle-title nzd4">
          <a
            href={`${this.props.vehicle.link}`}
            target="_self"
          >{`${this.props.vehicle.name}`}</a>
        </h3>
        <div className="button-wrapper">
          <div
            className={`blue-button ${
              this.props.vehicle.condition == "Brand New" ? "full-width" : ""
            }`}
          >
            <div className="price">{`$${this.props.vehicle.price}`}</div>
            <div className="sub-text">
            {!["used", "demonstrators"].includes(this.props.vehicle.condition.trim().toLowerCase() )
                ? "+ O.R.C"
                : ["JOHN MOURITS BUILDER", "JOHN MADOC", "NZ FARMERS", "", "DEALER STOCK"].includes(this.props.vehicle.rego) ? "+ O.R.C" : "Drive Away"}
            </div>
          </div>
          {["Used", "Demonstrators"].includes(this.props.vehicle.condition) ? (
            <div className="blue-button">
              <div className="price">{`$${this.props.vehicle.finance}`}</div>
              <div className="sub-text">Per Week*</div>
            </div>
          ) : null}
        </div>
        <div className="vehicle-data-row">
          <div className="vehicle-data">
            <div className="dataset">
              <div className="data-label">Transmission:</div>
              <div className="data-value">
                {" "}
                {` ${this.props.vehicle.transmission} `}
              </div>
            </div>
            <div className="dataset">
              <div className="data-label">Stock Number:</div>
              <div className="data-value">
                {" "}
                {` ${this.props.vehicle.stocknumber} `}
              </div>
            </div>
            <div className="dataset">
              <div className="data-label">Engine:</div>
              <div className="data-value">
                {" "}
                {` ${this.props.vehicle.engine} `}
              </div>
            </div>
            <div className="dataset">
              <div className="data-label">Fuel:</div>
              <div className="data-value">
                {" "}
                {` ${this.props.vehicle.fuel} `}
              </div>
            </div>
            <div className="dataset">
              <div className="data-label">Odometer (km):</div>
              <div className="data-value">
                {" "}
                {` ${this.props.vehicle.mileage}`}
              </div>
            </div>
          </div>
          <div className="view-details">
            <a href={`${this.props.vehicle.link}`} target="_self">
              VIEW DETAILS
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Listings;